.dashboard-container {
    display: flex;
    padding-top: 5rem;
    height: 90vh;
  }
  
  .sidebar {
    height: 100%;
    width: 250px;
    background-color: #f8f9fa;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    padding-top: 6rem;
  }
  
  .sidebar ul li {
    margin-bottom: 15px;
  }
  
  .sidebar ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    display: block;
  }
  
  .sidebar ul li.active a,
  .sidebar ul li a:hover {
    color: #d32f2f;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
  }
  
  @media (max-width: 768px) {
    .dashboard-container{
      padding-top: 0;
    }
    .sidebar {
      max-height: 200px !important;
      padding-top: 5rem;
      width: 100%
    }
  
    .sidebar ul{ 
      padding-top: 0;
    }
  
    .sidebar ul li {
      margin-bottom: 5px;
    }
  
    .dashboard-container {
      flex-direction: column;
    }
  }
  
  @media (max-width: 480px) {
    .dashboard-container{
      padding-top: 0;
    }
    .sidebar {
      max-height: 200px !important;
      padding-top: 5rem;
    }
  
    .sidebar ul{ 
      padding-top: 0;
    }
  
    .sidebar ul li {
      margin-bottom: 5px;
    }
    
  }
  