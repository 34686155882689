.auth-container {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 20vh;
    margin-bottom: 20vh;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #fff;
  }
  
  .auth-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .auth-container form {
    display: flex;
    flex-direction: column;
  }
  
  .auth-container input {
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;

  }
  .password-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    align-content: center;
    align-items: center;
    justify-content: start;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 10px 0;
  }

  .password-container input{
    width: 100%;
    border: none !important;
    margin-bottom: 0;
  }

  .password-container input:focus{
    border: none !important;
  }
  
  .toggle-password-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px !important;
    background-color: none !important;
  }
  
  .toggle-password-icon:focus {
    outline: none;
  }
  
  
  .auth-container button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #d32f2f;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-container button:hover {
    background-color: #b71c1c;
  }

  .auth-btn {
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .pass-btn{
    background: none !important;
    color: blue !important;
    text-decoration: underline;
  }

  .pass-btn:hover{
    color: #00004c !important;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .password-hint ul {
    list-style-type: none;
    padding: 0;
  }
  
  .password-hint li {
    color: red;
  }
  
  .password-hint li.met {
    color: green;
  }
  
  .error {
    color: red;
  }

  .reset-message {
    color: green;
  }
  
  .forget-hint {
    margin-top: -20px;
    text-align: right;
  }
  
  @media (max-width: 768px) {
    .auth-container {
      padding: 15px;
    }
  
    .auth-container h2 {
      font-size: 24px;
    }
  
    .auth-container input {
      font-size: 14px;
    }
  
    .auth-container button {
      font-size: 14px;
    }
  }
  