.payment-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 4rem;
  background-color: #f8f9fa;
}

.subscription-options {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.option {
  border: 1.5px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  margin: 0 10px;
  text-align: center;
  flex: 1;
}

.option-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.option-price {
  font-size: 24px;
  font-weight: bold;
  color: #ff4500;
}

.option-discount {
  color: green;
  font-size: 14px;
}

.legal-disclaimer {
  text-align: center;
  margin-top: 20px;
  color: #6c757d;
}

@media (max-width: 480px) {
  .payment-page {
    padding: 80px 10px;
  }
}
