.invoice-editor-container {
    padding: 20px;
    background-color: #fff;
    max-width: 95vw;
    margin: 120px auto;
    border: 1.5px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.invoice-editor-table-wrapper {
    overflow-x: auto; /* Allow horizontal scrolling */
    margin-top: 20px;
}

.invoice-editor-table {
    width: 100%; /* Table takes the full width */
    /* min-width: 900px; Ensure table maintains a minimum width */
    border-collapse: collapse;
    margin-top: 20px;
}

.invoice-editor-table th,
.invoice-editor-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
     min-width: 100px;
}

.invoice-editor-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1;
}

.invoice-editor-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.invoice-editor-table tr:hover {
    background-color: #f1f1f1;
}

.invoice-editor-input {
    width: 100%;
    border: none;
    padding: 8px;
    box-sizing: border-box;
    background-color: transparent;
}

.invoice-editor-input:focus {
    outline: none;
    background-color: #f0f8ff;
}

.invoice-editor-summary {
    margin-top: 20px;
    font-weight: bold;
    font-size: 1.1em;
}

.invoice-editor-buttons {
    position: sticky; /* Position fixed within the container */
    bottom: 0; /* Stick to the bottom */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    display: flex;
    justify-content: space-between;
   
    padding: 10px 20px;
    padding-top: 20px;
    background-color: #fff; /* Ensure background covers any content behind */
    /* box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); Slight shadow for distinction */
    z-index: 2; /* Ensure buttons stay above the table */
}

.invoice-editor-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #004999;
    color: white;
    font-size: 1em;
}

.invoice-editor-button:hover {
    background-color: #003366;
}

.invoice-editor-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.invoice-editor-reset-button {
    background-color: #d9534f;
}

.invoice-editor-reset-button:hover {
    background-color: #c9302c;
}

.invoice-editor-save-button {
    background-color: #5cb85c;
}

.invoice-editor-save-button:hover {
    background-color: #4cae4c;
}

@media (max-width: 768px) {
    .invoice-editor-container {
        max-width: 95%;
        padding: 10px;
        margin: 100px 10px;
    }

    .invoice-editor-table th,
    .invoice-editor-table td {
        padding: 8px;
    }

    .invoice-editor-buttons {
        flex-direction: column;
    }

    .invoice-editor-button {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .invoice-editor-container {
        padding: 10px 0;
        margin:100px 5px;
    }

    .invoice-editor-table th,
    .invoice-editor-table td {
        padding: 5px;
        font-size: 0.9em;
    }

    .invoice-editor-buttons {
        flex-direction: column;
    }

    .invoice-editor-button {
        width: 100%;
        margin: 10px 0;
    }
}
