.file-preview {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    padding-top: 100px;
  }

  .file-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
  }
  
  .file-preview-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    
  }
  
  .remove-single-invoice {
    color: red;
    cursor: pointer;
  }
  
  .remove-single-invoice:hover {
    color: #b71c1c;
  }
  
  .file-preview-image {
    width: 100px;
    height: 120px;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .file-preview-image-name{
    width: 120px;
  }

  .file-preview-item p {
    max-width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 5px 0 0;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .file-preview{
        gap: 0px;
        padding-top: 0px;
    }
    .file-preview-item {
      width: 50%; 
    }
  
    .file-preview-image {
      width: 180px;
      height: 180px;
      margin-top: 40px;
    }
  }
  
  @media (max-width: 480px) {
    .file-preview-item {
      width: 45%; /* Two items per row */
    }
  
    .file-preview-image {
      max-width: 50px;
      max-height: 50px;
    }
  }
  