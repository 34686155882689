/* Loading.css */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.loading {
  text-align: center;
}

.progress-container {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.progress-container p {
  margin-top: 10px;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;

  width: 0%;
  transition: width 0.4s ease;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Add loading animation */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-circle {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #d32f2f;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: rotate 2s linear infinite;
}

/* Responsive styles */
@media (max-width: 768px) {
  .loading-circle {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 480px) {
  .loading-circle {
    width: 40px;
    height: 40px;
  }
}
