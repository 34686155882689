.payment-success-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1.5px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 10rem;
  }
  
  .payment-success-container h2 {
    margin-bottom: 20px;
  }
  
  .quota-info {
    margin-bottom: 20px;
  }
  
  .quota-info p {
    margin: 5px 0;
  }
  
  /* button {
    padding: 10px 20px;
    background-color: #004999;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  button:hover {
    background-color: #00244c;
  } */
  