.about-us-container {
    padding: 2rem;
    max-width: 1000px;
    margin: 100px auto;
    font-family: 'Arial', sans-serif;
  }
  
  .about-us-container h1,
  .about-us-container h2 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  .story-section,
  .mission-section,
  .values-section {
    margin-bottom: 2rem;
  }
  
  .story-section p,
  .mission-section p,
  .values-section p {
    color: #666;
    line-height: 2;
    margin-bottom: 1rem;
  }

  .strong {
    font-size: 24px;
  }
  
  .mission-section {
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 8px;
  }
  
  .values-section {
    border-left: 4px solid #007bff;
    padding-left: 1rem;
  }

  @media (max-width: 768px){
    .about-us-container {
        margin: 50px auto;
      }
  }
  