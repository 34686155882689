/* InvoiceHistory.css */
.invoice-history-container {
  padding: 20px;
  background-color: #fff;
  max-width: 800px;
  margin: 0 auto;
  border: 1.5px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.invoice-history-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-history-table th,
.invoice-history-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.invoice-history-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.invoice-history-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.invoice-history-table tr:hover {
  background-color: #f1f1f1;
}

.invoice-history-table td a {
  color: #004999;
  text-decoration: none;
}

.invoice-history-table td a:hover {
  text-decoration: underline;
}

.history-error-message {
  color: red;
  font-size: 28px;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .invoice-history-container {
    margin-bottom: 50px;
      padding: 10px;
  }

  .invoice-history-table th,
  .invoice-history-table td {
      padding: 6px;
  }
}

@media (max-width: 480px) {
  .hide-in-mobile {
      display: none;
  }

  .invoice-history-container {
      padding: 0;
  }
}
