.modal-header {
    border-bottom: none;
  }
  
  .modal-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .modal-body {
    text-align: center;
    font-size: 18px;
  }

  
  @media (max-width: 768px) {
    .modal-title {
      font-size: 20px;
    }
  
    .modal-body {
      font-size: 16px;
    }
  
    button {
      width: 80px;
      margin: 8px;
    }
  }
  
  @media (max-width: 480px) {
    .modal-title {
      font-size: 18px;
    }
  
    .modal-body {
      font-size: 14px;
    }
  
    button {
      width: 70px;
      margin: 6px;
    }
  }
  