.footer {
    background-color: #f8f9fa;
    padding-top: 10px;
    text-align: center;
    border-top: 1px solid #e0e0e0;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-links {
    margin-bottom: 10px;
  }
  
  .footer-links a {
    margin: 0 10px;
    font-size: 14px;
    color: #333;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: #d32f2f;
  }
  
  .footer p {
    font-size: 14px;
    color: #777;
  }
  