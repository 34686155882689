/* UploadInvoice.css */
h1 {
  margin-bottom: 30px;
}

p {
  margin-bottom: 30px;
  font-size: 16px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.drop-zone{
  margin-top: 30px;
}

.preview-buttons {
  margin-bottom: 120px;
  margin-top:0 !important;
  padding-top:0 !important;
}

.main-button {
  font-size: 24px;
  padding: 20px 30px;
  margin-top: 40px;
  border-radius: 5px 
}

.main-button.verifying {
  opacity: 0.5;
}

.feedback-button {
  font-size: 24px;
  padding: 20px 30px !important;
  margin-top: 40px;
  border-radius: 5px 
}

.feedback-button:hover {
  border: none;
  background-color: #0d6efd !important;
  opacity: 0.8;
}

.mobile-buttons{
    display: none;
}

.cancel-button {
  background-color: #045760;
  font-size: 24px;
  padding: 20px 30px;
  margin-top: 40px;
  margin-left: 20px;
  border-radius: 5px 
}

.cancel-button:hover{
    background-color: #023c43 !important;
}

button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #b71c1c;
}

.download-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding-top: 180px;
}

.download-button,
.retry-button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

.download-button:hover:enabled,
.retry-button:hover:enabled {
  background-color: #b71c1c;
}

.download-button {
  background-color: #d32f2f;
  width: 30rem;
  color: white;
  border: none;
  padding: 0 !important;
  font-size: 28px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

.download-button:hover:enabled {
  background-color: #b71c1c;
}

.download-link {
  padding: 20px 0;
  height: 100%;
  width: 100%;
  color: white;
  text-decoration: none;
}

.download-link:hover {
  color: white;
  text-decoration: none;
}

.loading {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  animation: flash 2s infinite;
}

.highlight{
  color: #d32f2f;
  font-size: 26px;
}

.down-btn a{
  color: #d32f2f !important;
}

.down-btn{
  background-color: transparent !important;
  width: 130px !important;
  
  border: none !important;
}

.down-btn:hover{
  background-color: transparent !important;
  color: #962020 !important;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}


/* Add loading animation */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-circle {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #d32f2f;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: rotate 2s linear infinite;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Responsive styles */
@media (max-width: 768px) {
  .drop-zone {
    height: 80vh;
    padding: 40px 20px;
    
  }

  .select-files-button {
    width: 50%;
    font-size: 28px;
    padding: 20px 30px;
  }

  .file-preview-item {
    width: 100%;
  }

  .file-preview-image {
    width: 80px;
    height: 80px;
  }

  .mobile-buttons{
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

  .buttons {
    display:none;
  }

  button {
    width: 100%;
  }


  .download-section {
    padding-top: 50px;
  }

  .download-button,
  .main-button, .cancel-button {
    margin: 20px auto;
    width: 24rem;
    height: 8rem;
    font-size: 36px;
    padding: 20px 30px;
    border-radius: 5px ;
  }

  .cancel-button{
    margin-bottom: 40px;
  }

  .download-button {
    margin-top: 80px;
  }

  .retry-button {
    width: 16rem;
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  .drop-zone h1,
  .drop-zone p {
    font-size: 14px;
  }

  .select-files-button {
    font-size: 16px;
  }

  .file-preview-image {
    width: 60px;
    height: 60px;
  }

  .drag-msg {
    display: none;
  }

  .download-button,
  .main-button, .cancel-button {
    margin: 20px auto;
    width: 18rem;
    height: 6rem;
    font-size: 28px;
    padding: 16px 24px;
  }


  .download-button {
    margin-top: 60px;
  }


  .retry-button {
    width: 12rem;
    font-size: 22px;
    margin-top: 50px;
  }
}
