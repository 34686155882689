.payment-form {
  width: 60%;
  min-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1.5px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.payment-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.basicPlan {
  border-top: 1rem solid #81d681;
}

.basicPlan h3,
.basicPlan p {
  color: #81d681;
}

.basicPlan h4 {
  background: #81d681;
}

.basicPlan p {
  border: 1px solid #81d681;
}

.standardPlan {
  border-top: 1rem solid #2986cc;
}

.standardPlan h3,
.standardPlan p {
  color: #2986cc;
}

.standardPlan h4 {
  background: #2986cc;
}

.standardPlan p {
  border: 1px solid #2986cc;
}


.premiumPlan {
  border-top: 1rem solid #f6b26b;
}

.premiumPlan h3,
.premiumPlan p {
  color: #f6b26b;
}

.premiumPlan h4 {
  background: #f6b26b;
}

.premiumPlan p {
  border: 1px solid #f6b26b;
}

.amount-selection {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  gap: 30px;
  flex-wrap: wrap;
}

.plan {
  width: 100%;
  max-width: 220px;
  padding: 30px 20px;
  text-align: center;
  transition: transform 0.3s, border-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #f3f6f4;
  cursor: pointer;
  opacity: 0.5;
}

.plan h2 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.plan h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.plan h4 {
  color: white;
  font-size: 1.2em;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 6px;
}

.plan p {
  margin-bottom: 15px;
  font-size: 12px !important;
  background: white;
  padding: 3px 0;
  border-radius: 12px;
}

.plan span {
  font-size: 18px;
}

.plan strong {
  font-size: 26px;
}

.plan.selected {
  transform: scale(1.08);
  opacity:1;
}

.form-section {
  margin: 30px 0;
}

.form-section h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
}

.user-details-form,
.company-details-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-address-form{
  display: flex;
  flex-direction: column;

}

.user-address-form label {
  text-align: start;
}

.user-address-form .upper-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-address-form .lower-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.user-details-form label,
.company-details-form label {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.extra-quota-section button {
  border-radius: 5px;
}

.contact-us-container {
margin-top: 10px;
}

.paymentInput {
  width: 90%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-error {
  border: 2px solid red;
}

.input-normal {
  border: 1px solid #ccc; 
}


.error-message,
.success-message {
  text-align: center;
  margin-top: 10px;
}

.error-message,
.vat-error {
  color: red !important;
}

.success-message,
.vat-ok {
  color: green;
}

.paymentbtn {
  position: relative;
  margin-top: 10px;
  width: 100%;
  border-radius: 5px;
  font-size: 22px;
  padding: 15px;
  transition: opacity 0.3s;
}

.paymentbtn.loading {
  opacity: 0.5;
}

.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  z-index: 9999; /* Ensure it is above all other content */
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #3498db; /* Color of the spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-spinner p {
  margin-top: 10px;
  font-size: 1.2em;
  color: #3498db; /* Text color */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.button-disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}


@media (max-width: 768px) {
  .payment-form {
    width: 100%;
    min-width: 100%;
  }

  .amount-selection {
    flex-direction: column;
    align-items: center;
  }

  .plan {
    width: 80%;
    max-width: 300px;
    margin-bottom: 20px;
    padding: 10px;
  }

  .plan h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .plan h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .plan p {
    margin-bottom: 15px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .plan.selected {
    transform: scale(1.1);
  }

  .user-address-form .upper-container,  .user-address-form .lower-container, .company-details-form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .user-address-form .lower-container label {
    width: 100% !important;
  }

}