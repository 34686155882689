.drop-zone {
    text-align: center;
    transition: background-color 0.3s ease;
    font-family: Arial, sans-serif;
    position: relative;
    height: calc(100vh - 60px); /* Adjust to account for the navbar height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    max-width: 100%; /* Ensure it doesn't exceed viewport width */
    background-color: #f0f0f0;
    box-sizing: border-box;
    padding: 40px; /* Add padding for better appearance */
  }
  
  .drop-zone.drag-over {
    background-color: #4c4c50 !important;
    z-index: 3;
  }
  
  .select-files-button {
    background-color: #d32f2f;
    
    color: white;
    border: none;
    padding: 20px 40px;
    font-size: 28px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 1rem auto;
  }
  
  .select-files-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .select-files-button:hover:enabled {
    background-color: #b71c1c;
  }
  
  .error-message {
    color: red;
    font-size: 24px;
    margin-top: 10px;
    font-family: Arial, sans-serif;
    text-align: center;
    font-weight: bold;
    /* animation: flash 1.2s step-end infinite alternate; */
  }
  
  .drop-zone p {
    line-height: 1.8;
  }
  
  .description {
    margin: 1rem 0 !important;
  }

  .free-font{
    font-size: 40px;
  }

  .invoice-no-font{
    font-size: 26px;
  }
  
  .strong {
    color: #d32f2f;
  }
  
  @keyframes flash {
    0% {
      opacity: 1;
    }
    80% {
      opacity: 0.5;
    }
  }
  
  
  @media (max-width: 768px) {
    .drop-zone {
      width: 100%;
      max-width: 100%; /* Ensure it doesn't exceed viewport width */
      overflow-wrap: break-word;
      height: calc(100vh - 60px); /* Adjust to account for the navbar height */
    }
  
    .drop-zone h1 {
      font-size: 28px;
    }
  
    .drop-zone p {
      font-size: 20px;
      
    }
    .error-message {
      font-size: 24px;
      margin-top: 10px;
    }
  
    .select-files-button {
      width: 18rem;
      height: 6rem;
      font-size: 28px;
      padding: 16px 24px;
    }
  }
  
  @media (max-width: 480px) {
    .drop-zone {
      justify-content: start;
      height: calc(100vh - 50px); /* Adjust to account for the navbar height */
    }
  
    .drop-zone h1 {
      font-size: 24px;
      margin-top: 60px;
    }
  
    .drop-zone p {
      font-size: 16px;
    }
  
    .select-files-button {
      width: 18rem;
      height: 5rem;
      font-size: 28px;
      padding: 16px 24px;
    }
  
    .error-message {
      font-size: 18px;
      margin-top: 26px;
    }

    .free-font{
        font-size: 28px;
      }

      .invoice-no-font{
        font-size: 22px;
      }
  }
  