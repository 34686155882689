.privacy-policy-container {
    margin: 100px auto;
    max-width: 1000px;
    padding: 20px;
    line-height: 2;
    font-family: Arial, sans-serif;
  }
  
  .privacy-policy-container h1, .privacy-policy-container h2 {
    text-align: start;
  }

  .privacy-policy-container h2{
    font-size: 24px;
    margin-top: 30px;
    text-decoration: underline;
  }
  
  .privacy-policy-container p {
    margin: 10px 0;
    text-align: start;
  }
  
  .privacy-policy-container a {
    color: #004aad;
    text-decoration: none;
  }
  
  .privacy-policy-container a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px){
    .privacy-policy-container {
        margin: 60px auto;
      }
  }
  