.account-container {
  padding: 50px 50px;
  max-width: 600px;
  margin: 50px auto;
  margin-top: 3rem;
  border: 1.5px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.account-form {
  display: flex;
  flex-direction: column;
}

.account-form label {
  margin: 10px 20px;
  font-weight: bold;
    text-align: left;
}

.quota-bar {
    margin-bottom: 30px;
  }
  
  .quota-bar h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
  }
  
  .quota-bar span{
      font-size: 14px;
      margin-left: 20px;
  }
  
  .quota-meter {
    width: 100%;
    background-color: #d8d8d8;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .used-quota {
    background-color: #559900;
    color: #fff;
    padding: 10px;
    text-align: center;
    white-space: nowrap;
    transition: width 0.3s ease-in-out;
  }

.account-form input,
.account-form .react-select__control {
  padding: 8px;
  margin-left: 20px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  width: 300px;
}

.account-form .disableInput{
  border: none !important;
  background: none;
  max-width: 250px;
  padding: 8px 0;
  text-align: start;
}

.account-button {
  padding: 10px;
  background-color: #004999;
  color: white;
  border: none;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 4px;
  padding: 8px;
    width: 80% !important;
}

.country {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .country-select {
    width: 5rem;
  }
}

.account-button:hover {
  background-color: #00244c !important;
}

.error-message {
  color: red;
  margin-top: 10px;
}



.cancel-sub {
  background-color: transparent !important;
  width:80% !important;
  color: #d32f2f !important;
  border: none !important;
  margin: 0 auto;
  margin-bottom: 20px;
}

.cancel-sub:hover {
  background-color: transparent !important;
  color: #962020 !important;
}

.change-sub {
  background-color: #d32f2f !important;
  color: white !important;
  margin: 0 auto;
  width:80% !important;
  border: none !important;
  margin-bottom: 10px;
}

.change-sub:hover {
  background-color: #b71c1c !important;
}

@media (max-width: 768px) {
  .account-container {
    padding: 10px;
  }

  .account-form label {
    margin-bottom: 8px;
  }

  .account-form input,
  .account-form .react-select__control {
    padding: 6px;
    margin-bottom: 8px;
    margin-left: 0 !important;
    width: 100%;
  }
}

@media (max-width: 480px){
    .account-container{
        padding: 10px;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .account-form input{
        padding: 6px;
      margin-top: 10px;
      margin-left: 0 !important;
      width: 100%; /* Ensure responsiveness */
    }
}