.company-info-container {
    padding: 20px;
    background-color: #fff;
    max-width: 600px;
    margin: 0 auto;
    border: 1.5px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .company-info-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .company-info-form label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left;
    width: 100%;
  }
  
  .company-info-input {
    padding: 8px;
    margin: 10px 0;
    
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    /* max-width: 400px;  */
    text-align: center; 
    justify-content: end;
  }
  
  .company-info-button {
    padding: 10px;
    background-color: #004999;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 4px;
    width: 275px;
  }
  
  .company-info-button:hover {
    background-color: #00244c !important;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .success-message {
    color: green;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .company-info-container {
      padding: 20px;
      margin-top: 2rem;
    }
  
    .company-info-input {
      padding: 6px;
      width: 100%; 
    }

    .company-info-form label {
        margin-bottom: 20px;
    }
  
    .company-info-button {
      padding: 8px;
    }
  }

  @media (max-width: 480px) {
    .company-info-container {
      padding: 10px;
      margin-top: 0;
      margin-bottom: 10px;
    }
  
    .company-info-form{
        flex-wrap: wrap;
    }
    .company-info-input {
      padding: 6px;
      margin-top: 10px;
      margin-left: 0 !important;
      width: 100%; /* Ensure responsiveness */
    }
  
    .company-info-button {
      padding: 8px;
    }
  }
  