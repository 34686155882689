/* NavBar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed !important;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.desktop-navbar {
  display: flex;
  padding: 10px 30px;
  justify-content: space-between;
  width: 100%;
  position: fixed !important;
}

.mobile-navbar {
  display: none;
  flex-direction: column;
  width: 100%;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 40px;
  margin-right: 10px;
}



.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-links a {
  margin: 0 30px;
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.navbar-links a:hover {
  color: #d32f2f;
}

.navbar-actions {
  display: flex;
  align-items: center;
}

.navbar-button {
  background-color: #d32f2f;
  text-decoration: none;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.title-btn{
  background: none !important;

  .navbar-title {
    color: #d32f2f;
    font-size: 24px;
    font-weight: bold;
  }

  .navbar-title:hover{
    font-size: 25px;
  }
}

.login {
  background-color: transparent !important;
  width: 130px !important;
  color: #d32f2f !important;
  border: none !important;
}

.login:hover {
  background-color: transparent !important;
  color: #962020 !important;
}

.signup {
  background-color: #d32f2f !important;
  color: white !important;
  margin-right: 20px ;
  min-width: 100px !important;
  border: none !important;
}

.signup:hover {
  background-color: #b71c1c !important;
}

.menu {
  display: none;
}

.navbar-language-toggle{
  display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100px;
    
}

.navbar-language-toggle select {
  border: none;
}

@media (max-width: 768px) {
  .navbar {
    height: 4rem;
    position: fixed !important;
  }

  .desktop-navbar {
    display: none;
  }

  .mobile-navbar {
    display: flex;
    padding-left: 20px;
    position: fixed;
  }

  .navbar-links-mobile {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: fixed;
    top: 60px;
    right: 1rem;
    width: 15rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .navbar-link-column {
    border-bottom: 1px solid grey;
    padding: 20px;
    font-size: 18px;
  }

  .navbar-link-column:last-child {
    border-bottom: none;
  }

  .navbar-links-mobile a {
    margin: 10px 0;
    text-align: center;
    color: #333;
    text-decoration: none;
  }

  .navbar-links-mobile a:hover{
    background-color: #f1f1f1;
  }

  .menu {
    display: block;
    width: 3.2rem;
    position: fixed;
    right: 0.5rem;
  }

  .navbar-language-toggle{
    margin:0 auto;
      width: 50%;
      
  }
}

@media (max-width: 480px) {
  .navbar-links-mobile {
    width: 10rem;
  }

  .navbar-link-column {
    padding: 10px;
    font-size: 16px;
  }
}

